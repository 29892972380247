#manage {
  flex: 1;
  margin-left: 2px;
  .nav-header {
    height: 62px;
    background: #fff;
    margin-bottom: 2px;
    padding-left: 18px;
    padding-top: 24px;
    .nav-btn {
      height: 22px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .nav-back {
        width: 12px;
        height: 12px;
        margin-right: 8px;
        transform: rotate(90deg);
        -ms-transform: rotate(90deg); /* IE 9 */
        -moz-transform: rotate(90deg); /* Firefox */
        -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
        -o-transform: rotate(90deg); /* Opera */
      }
      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .info-bar {
    height: 124px;
    padding: 12px 24px;
    background: #fff;
    .infoItem {
      overflow: hidden;
      margin-bottom: 24px;
      .info-item {
        overflow: hidden;
        float: left;
        height: 36px;
        margin-right: 50px;
        .title {
          float: left;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 36px;
          margin-right: 4px;
        }
        .icon {
          float: left;
          width: 12px;
          height: 12px;
          border-radius: 9px;
        }
      }
    }
    .infoSelet {
      overflow: hidden;
      .ant-select {
        float: left;
        display: flex;
        align-items: center;
        margin-right: 24px;
        .select-down {
          width: 12px;
          height: 12px;
          margin-top: -50%;
        }
        .ant-select-selection-item {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
      .info-slider {
        float: left;
        display: flex;
        align-items: center;
        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .slider-title {
          font-size: 14px;
          margin-right: 14px;
        }
        .ant-slider {
          width: 173px;

          .ant-slider-rail {
            background-color: #d8d8d8;
          }
          .ant-slider-handle {
            width: 6px;
            height: 13px;
            background: #3d7fff;
            border-radius: 3px;
            border: none;
            &:focus {
              box-shadow: none;
            }
          }

          .ant-slider-track {
            background-color: #d8d8d8;
          }
        }
      }
      .info-delete {
        float: right;
        .delete-btn {
          width: 10px;
          height: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .info-data {
    margin-top: 12px;
    margin-left: 10px;
    margin-bottom: 46px;
    display: flex;
    .data-mumber {
      width: 218px;
      height: 636px;
      .data-mumber-title {
        height: 50px;
        background: #ffffff;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #3d4145;
        margin-bottom: 2px;
      }
      .data-mumber-list {
        height: 614px;
        background-color: #fff;
        padding-left: 24px;
        padding-top: 24px;
        .data-mumber-list-item {
          margin-bottom: 24px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
        }
      }
    }
    .data-image {
      flex: 1;
      height: 636px;
      margin-left: 12px;
      .data-image-choose {
        font-size: 15px;
        height: 50px;
        padding-left: 40px;
        line-height: 50px;
        background: #ffffff;
        margin-bottom: 2px;
        .choosen-num {
          margin-left: 32px;
          margin-right: 25px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .ant-btn-link {
          span {
            text-decoration: underline;
          }
        }
      }
      .data-image-list {
        height: 614px;
        padding: 38px 40px;
        background: #ffffff;
        .imageCanvas {
          display: grid;
          grid-template-columns: repeat(4, 176px);
          grid-column-gap: 40px;
          grid-row-gap: 40px;
          .imageCanvas-item {
            height: 142px;
            background: #f3f3f3;
            border-radius: 4px;
            position: relative;
            display: none;
            .fckIcon {
              position: absolute;
              top: 0px;
              right: 0px;
              width: 30px;
              height: 30px;
              background: #ffffff;
              border-radius: 3px 0px 3px 0px;
              border: 1px solid #d7dbe0;
              display: flex;
              justify-content: center;
              align-items: center;
              &:hover {
                cursor: pointer;
              }
            }
            .selectIcon {
              left: 0px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .ant-pagination {
    text-align: center;
  }
}

.ant-modal-root {
  display: none;
  .ant-modal-body {
    canvas {
      margin-top: 25px;
    }
  }
}
