* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
/* .clearfix::after{
	content: "";
	display: block;
	height: 0;
	clear: both;
}
.clearfix{
	zoom: 1;
} */
body {
  background: #f5f5f5;
  /* min-width: 1440px; */
}

/* 顶部标题 */
header {
  box-sizing: border-box;
  /* width: 1440px; */
  height: 50px;
  padding-top: 15px;
  padding-left: 30px;
  background: #fff;
  margin-bottom: 2px;
}
header .title {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  display: flex;
  align-items: center;
}
header .title img {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}
/* 主内容区 */
.content {
  display: flex;
}
/* 侧边导航栏 */
nav {
  box-sizing: border-box;
 min-width: 200px;
  height: 974px;
  background: #fff;
  
}
.nav-title {
  height: 132px;
  text-align: center;
  line-height: 132px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.nav-item {
  height: 54px;
  padding-left: 30px;
  line-height: 54px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
}
.nav-item span {
  vertical-align: middle;
}
.nav-item img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
}
.nav-item-active {
  color: #fff;
  background-color: #3d7fff;
}